@import '@angular/material/theming';
@import 'variables';

@include mat-core();

$palette-primary: mat-palette($primary-color, 900);
$palette-accent: mat-palette($accent-color, 500);

$main-theme: mat-light-theme(
  (
    color: (
      primary: $palette-primary,
      accent: $palette-accent,
    ),
  )
);

@mixin custom-component($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  .mat-stroked-button.mat-accent {
    border-color: mat-color($accent, 700) !important;
  }

  .mat-stroked-button.mat-primary {
    border-color: mat-color($primary, 900) !important;
  }

  .mat-button-toggle-group {
    border: 1px solid mat-color($accent, 700) !important;
    height: 2.5rem;
    align-items: center;
  }

  .mat-button-toggle {
    background-color: transparent !important;
    color: mat-color($accent, 700) !important;
  }

  .mat-button-toggle-checked {
    background-color: mat-color($accent, 700) !important;
    color: mat-color($accent, default-contrast) !important;
  }
}

$montserrat-typography: mat-typography-config(
  $font-family: 'Montserrat',
);

@include angular-material-typography($montserrat-typography);
@include custom-component($main-theme);
@include angular-material-theme($main-theme);
