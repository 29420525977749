@import './zenki-theme';
@import './normalize';
@import './custom';

html,
body {
  height: 100%;
}
body {
  background-color: #f6f6f6;
  margin: 0;
}
* {
  font-family: 'Montserrat';
}
